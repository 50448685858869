import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { NextPage } from 'next'
import { Case, Switch } from 'react-if'
import { slugs } from '@/constants'
import { HOMESTEAD_SERIES_EPISODE_1_ID } from '@/constants/episode-ids'
import { paths } from '@/constants/paths'
import { projectTypes } from '@/constants/projectTypes'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { TheatricalRegionProvider } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { Seo } from '@/layout/Seo'
import { MovieJsonLd, PodcastSeriesJsonLd, TVSeriesJsonLd } from '@/layout/Seo/JsonLd'
import {
  getJsonLdForMovie,
  getJsonLdForPodcastSeries,
  getJsonLdForTVSeries,
  useMetaForProject,
} from '@/layout/Seo/ProjectSeoHelper'
import { GetAppBannerProvider } from '@/organisms/GetAppBanner'
import { ShopifyConfigProvider } from '@/organisms/HorizontalShopifyMerchScroller/ShopifyConfigContext'
import { WatchDepartment } from '@/organisms/WatchDepartments/WatchDepartments'
import { getPageByPath } from '@/services/CmsService/Pages'
import { CatalogTitle, fetchTitle } from '@/services/ContentCatalog'
import { createWatchProjectDeepLink } from '@/services/DeepLinkService/DeepLinkService'
import { getFeatureAnnouncement } from '@/services/FeatureAnnouncementService'
import { LightFranchise } from '@/services/FranchiseService'
import { isInGuildAccess } from '@/services/PhaseManagerService'
import { getContentfulProjectTheme } from '@/services/ProjectThemeService'
import {
  Project,
  ProjectMeta,
  fetchFranchiseData,
  fetchTheatricalReleaseData,
  fetchTheatricalReleaseMono,
  getProjectAbout,
  getProjectGuildScore,
  getWatchProjectEarlyAccess,
  getWatchableEpisodeByGuid,
} from '@/services/ProjectsService'
import { PageModel, fetchPageDataContext } from '@/services/RenderService'
import { getShopifyConfigurationForProject } from '@/services/ShopifyConfigurationService/ShopifyConfigurationService'
import {
  GetTheatricalPreReleasePageDataResult,
  getTheatricalPreReleasePageData,
} from '@/services/TheatricalPreReleaseService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { TheatricalReleaseObject } from '@/types/codegen-federation'
import { getProjectInEarlyAccess } from '@/utils/GuildUtil'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { isDevelopmentEnvironment } from '@/utils/environment-utils'
import { logger } from '@/utils/logging'
import { getServerSidePropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { shouldHideFromSearchEngines } from '@/utils/seo/searchEngineHelper'
import { loadTranslations } from '@/utils/translate/translate-server'
import { getUserGuildStatus } from '@/utils/users/usersServer'
import { WatchProjectView, WatchProjectViewProps } from '@/views/WatchProjectView'

interface WatchProjectPageProps extends WatchProjectViewProps {
  catalogTitle?: CatalogTitle
  supportedTheatricalRegions: TheatricalRegion[]
  theatricalRegion: TheatricalRegion[]
  locale: string
  watchProjectPage: PageModel
  contentfulProjectTheme: ProjectTheme
  deepLink: string
  projectSlug: string
  pageData: GetTheatricalPreReleasePageDataResult
  theatricalRelease: TheatricalReleaseObject
  isProjectInEarlyAccess: boolean
  projectData: Project
  guildScore?: number
  isGuildMember?: boolean
  isLoggedIn?: boolean
  franchise?: LightFranchise
  hasWatchableReasons?: boolean
}

export const WatchProjectPage: NextPage<WatchProjectPageProps & ProjectMeta> = (props) => {
  const meta = useMetaForProject(props)
  const shouldHide = shouldHideFromSearchEngines(props)

  return (
    <>
      <Seo {...meta} nofollow={shouldHide} noindex={shouldHide} path={`${paths.watch.index}/${props.slug}`} />
      <Switch>
        <Case condition={props.projectType === projectTypes.movie}>
          <MovieJsonLd {...getJsonLdForMovie(props)} />
        </Case>
        <Case condition={props.projectType === projectTypes.podcast}>
          <PodcastSeriesJsonLd {...getJsonLdForPodcastSeries(props)} />
        </Case>
        <Case condition={props.projectType === projectTypes.series || props.projectType === projectTypes.special}>
          <TVSeriesJsonLd {...getJsonLdForTVSeries(props)} />
        </Case>
      </Switch>
      <ShopifyConfigProvider
        storeUrl={props.shopifyConfig?.storeUrl}
        accessToken={props.shopifyConfig?.accessToken}
        handle={props.shopifyConfig?.handle}
        storeHref={props.shopifyConfig?.storeHref}
      >
        <ProjectMetaProvider project={props}>
          <TheatricalRegionProvider
            supportedTheatricalRegions={props.supportedTheatricalRegions}
            regionData={props.theatricalRegion}
          >
            <GetAppBannerProvider deepLink={props.deepLink} projectSlug={props.projectSlug} context="watch">
              <WatchProjectView {...props} />
            </GetAppBannerProvider>
          </TheatricalRegionProvider>
        </ProjectMetaProvider>
      </ShopifyConfigProvider>
    </>
  )
}

export const getServerSideProps = getServerSidePropsErrorHandler(
  async ({ federationClient, locale, next404, params, preview, userId }) => {
    const guildStatus = !userId ? null : await getUserGuildStatus(userId)
    const projectSlug = getItemOrFirstEntry(params?.project)

    if (!projectSlug) {
      logger().error('The project slug is missing in /watch/[projectSlug]!', { params })
      return next404()
    }

    const redirect = getRedirect(projectSlug)
    if (redirect) return redirect

    const projectMeta = await getProjectAbout({ slug: projectSlug }, federationClient, { locale, preview })
    if (!projectMeta) {
      return next404()
    }

    const franchise = await fetchFranchiseData(projectMeta.franchiseDetails?.slug)

    const guildScore = await getProjectGuildScore(projectSlug)

    const theatricalSlug = projectSlug
    const shouldIncludePreview = isDevelopmentEnvironment()

    const theatricalRelease = await fetchTheatricalReleaseData({
      projectType: projectMeta.projectType,
      primaryFlowPhases: projectMeta?.primaryFlowPhases || [],
      theatricalSlug,
      shouldIncludePreview,
      locale,
    })

    const regionData = theatricalRelease?.regionCollection ?? null
    const supportedTheatricalRegions = theatricalRelease?.supportedTheatricalRegions ?? null
    const hasPayItForward = projectMeta?.pifEnabled || false
    const shopifyConfig = await getShopifyConfigurationForProject(projectSlug, { locale, preview })
    const theatricalReleaseMono = await fetchTheatricalReleaseMono({
      projectType: projectMeta.projectType,
      primaryFlowPhases: projectMeta?.primaryFlowPhases || [],
      theatricalSlug,
      client: federationClient,
    })

    const page = await getPageByPath(`${paths.watch.index}/${projectSlug}`, { preview, locale })
    const pageData = await getTheatricalPreReleasePageData({ slug: projectSlug }, federationClient, {
      locale,
      preview,
    })
    const pageDataContext = page ? await fetchPageDataContext(page, preview, locale) : null
    const catalogTitle = await fetchTitle<CatalogTitle>(locale, projectMeta?.contentCatalogId)
    const projectData = await getWatchProjectEarlyAccess(projectSlug, projectMeta, federationClient)

    const isProjectInEarlyAccess = Boolean(
      isInGuildAccess(projectData?.primaryFlowPhases ?? []) || getProjectInEarlyAccess(projectData),
    )
    const contentfulProjectTheme = await getContentfulProjectTheme(projectSlug, preview)
    const path = `${paths.watch.index}/${projectSlug}`

    let watchableEpisode = null
    watchableEpisode = await fetchWatchableEpisode(userId, projectSlug, federationClient)

    const hasWatchableReasons = Boolean(
      watchableEpisode?.watchableReasons?.length && watchableEpisode?.watchableReasons.length > 0,
    )

    const deepLink = await createWatchProjectDeepLink(
      {
        path,
        projectSlug,
        projectName: projectMeta?.name,
        contentName: projectMeta?.name,
        audioLocale: locale,
        captionLocale: locale,
      },
      {
        campaign: 'prompt-app-install',
        feature: 'app-prompt',
        channel: 'angel-web',
      },
    )

    return {
      props: {
        catalogTitle: omitUndefineds(catalogTitle),
        department: WatchDepartment.watch,
        hasPayItForward,
        locale,
        shopifyConfig,
        theatricalRegion: regionData,
        supportedTheatricalRegions,
        projectSlug,
        deepLink,
        ...projectMeta,
        featureAnnouncement: getFeatureAnnouncement(params?.project as string),
        page,
        pageData,
        pageDataContext,
        contentfulProjectTheme,
        theatricalRelease: theatricalReleaseMono,
        isProjectInEarlyAccess,
        projectData,
        hasWatchableReasons,
        franchise: omitUndefineds(franchise),
        guildScore: guildScore?.guildScore ?? null,
        isGuildMember: Boolean(guildStatus && guildStatus?.isGuildMember),
        isLoggedIn: Boolean(userId),
        ...(await loadTranslations(locale, [
          'common',
          'home',
          'watch',
          'app-promo',
          'ads',
          'account',
          'guild',
          'theatrical-presales',
        ])),
      },
    }
  },
)

export default WatchProjectPage

const fetchWatchableEpisode = async (
  uuid: string | null,
  projectSlug: string,
  ssrClient: ApolloClient<NormalizedCacheObject>,
) => {
  if (uuid && projectSlug === slugs.homesteadSeries) {
    return await getWatchableEpisodeByGuid(HOMESTEAD_SERIES_EPISODE_1_ID, ssrClient)
  }
  return null
}

const getRedirect = (projectSlug: string) => {
  if (projectSlug === slugs.truthAndConviction) {
    return {
      redirect: {
        destination: paths.watch.truthAndTreason,
        permanent: false,
      },
    }
  }

  if (projectSlug === 'the-last-rodeo') {
    return {
      redirect: {
        destination: paths.watch.theLastRodeo,
        permanent: false,
      },
    }
  }

  return null
}
